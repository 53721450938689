import { t } from '@lingui/macro'
import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { ModalProps } from '@nbit/arco'
import { welfareCenterMarketingImgUrl } from '@/constants/welfare-center'
import { renderNoReward, renderRewardList } from '@/helper/marketing/sign-in-activity/index'
import SignInActivityBaseModal from '../common-modal'

import styles from './index.module.css'

export enum RuleType {
  /** 首次签到 */
  FIRST = 'first',
  /** 每日签到 */
  SINGLE = 'single',
  /** 连续签到 */
  CONTINUITY = 'continuity',
}
export enum GivenType {
  /** 固定奖励 */
  FIXED = 'fixed',
  /** 随机奖励 */
  RANDOM = 'random',
}
type Reward = {
  // TODO: Define the type in types.d.ts with interface
  ruleType: string
  givenType: string
  symbol: string
  quantity: string
  days?: number
}
export type OpenParameters = {
  /** 奖励金额（包含币种） */
  rewards: Reward[]
}
export interface ExposeRef {
  /** 打开弹窗 */
  open: (data: OpenParameters) => void
}

/** 金币图片地址 */
const COIN_IMAGE_URL = `${welfareCenterMarketingImgUrl}sign-in/icon_image_light_gold.png`

const RewardDialog = forwardRef<ExposeRef>(function (_, ref) {
  const [visible, setVisible] = useState<boolean>(false)
  const staticDataRef = useRef<OpenParameters>()

  const closeModal = () => setVisible(false)
  const hasReward = !!staticDataRef.current?.rewards?.length
  const okText = hasReward
    ? t`features_marketing_sign_in_activity_reward_dialog_index_nmo0lccthj`
    : t`features_trade_spot_index_2510`
  const modalProps: ModalProps = {
    className: styles['reward-dialog'],
    visible,
    okText,
    onOk: closeModal,
    onCancel: closeModal,
  }

  useImperativeHandle(
    ref,
    () => ({
      open: data => {
        staticDataRef.current = { ...data }
        setVisible(true)
      },
    }),
    []
  )

  return (
    <SignInActivityBaseModal {...modalProps}>
      <div>
        <div className="dialog-title">{t`features_marketing_sign_in_activity_reward_dialog_index_4mv2aaaszm`}</div>
        <div className="reward-image-box">
          <img className="reward-image" src={COIN_IMAGE_URL} alt="reward" />
        </div>
        <div className="reward-content-box">
          {hasReward ? renderRewardList(staticDataRef.current?.rewards ?? []) : renderNoReward()}
        </div>
      </div>
    </SignInActivityBaseModal>
  )
})

export default RewardDialog
