import { getAllGuideMark, GetSignInInfo, updateGuideMark } from '@/apis/marketing/sign-in'
import { useSignInActivityStore } from '@/store/marketing/sign-in-activity'
import { useRequest } from 'ahooks'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useUserStore } from '@/store/user'
import { RemindStatusEnum, GuideStatusEnum, SignInGuideKey } from '@/constants/marketing/sign-in-activity'
import { isActivityValid } from '@/helper/marketing/sign-in-activity'

const useSignInActivity = () => {
  const {
    hasSignIn,
    setHasSignIn,
    shouldShowIntro,
    setShouldShowIntro,
    shouldRemind,
    setShouldRemind,
    activiy,
    setActivity,
    loading,
    setLoading,
    resetStore,
  } = useSignInActivityStore()
  const { runAsync: fetchActivityInfo } = useRequest(GetSignInInfo, { manual: true })
  const { runAsync: fetchGuideData } = useRequest(getAllGuideMark, { manual: true })
  const { runAsync: fetchUpdateGuide } = useRequest(updateGuideMark, { manual: true })
  const userStore = useUserStore()

  const closeIntro = () => {
    setShouldShowIntro(false)
    fetchUpdateGuide({
      uid: userStore?.userInfo?.uid,
      key: SignInGuideKey,
    })
  }
  const handleSignIn = () => {
    setHasSignIn(true)
  }
  const closeRemind = () => {
    setShouldRemind(false)
  }
  /** 请求活动关键信息 */
  const requestActivityInfo = async () => {
    const infoRes = await fetchActivityInfo({})
    const { lastSignDate, remindStatus } = infoRes?.data || {}
    const hasInfo = isActivityValid(infoRes?.data)
    if (!hasInfo) return { hasInfo: false, remind: false, hasFinished: false }
    const hasFinished = dayjs().isSame(lastSignDate, 'day')
    setHasSignIn(hasFinished)
    setActivity(infoRes?.data)
    return { hasInfo, remind: remindStatus === RemindStatusEnum.open && !hasFinished, hasFinished }
  }
  /** 组装指引数据 */
  const composeActivityInfo = async () => {
    try {
      /** 这里先处理活动关键信息，处理完之后再请求指引数据 避免冗余请求 */
      setLoading(true)
      const { hasInfo, remind, hasFinished } = await requestActivityInfo()
      if (!hasInfo) return
      const guideRes = await fetchGuideData({
        uid: userStore?.userInfo?.uid,
      })
      setShouldShowIntro(guideRes?.data?.sign_guide === GuideStatusEnum.open && !hasFinished)
      requestAnimationFrame(() => {
        // TODO: 降低优先级 使用 useTransition 更佳 当前 react 版本不支持
        setShouldRemind(remind)
      })
    } finally {
      /***/
    }
  }

  useEffect(() => {
    /** 这个地方存在 isLogin为true userInfo没有的情况 故同时依赖两者 */
    if (userStore?.isLogin && userStore?.userInfo?.uid && !activiy && !loading) {
      composeActivityInfo()
    }
    /** 切换账号/退出登录 */
    if (!userStore?.isLogin) {
      resetStore()
    }
  }, [userStore?.isLogin, userStore?.userInfo?.uid, activiy, loading])

  return {
    /** 活动基础信息 */
    activiy,
    /** 是否有签到活动 */
    hasActivity: isActivityValid(activiy),
    /** 是否显示签到指引 */
    shouldShowIntro,
    /** 是否显示签到提醒 */
    shouldRemind,
    /** 是否已签到 */
    hasSignIn,
    /** 关闭签到指引 */
    closeIntro,
    /** 签到 */
    handleSignIn,
    /** 关闭签到提醒 */
    closeRemind,
  }
}

export default useSignInActivity
